import { useTranslation } from "react-i18next";

export function useTrackingEvent(event) {
  const { t } = useTranslation();
  const events = [
    {
      event_type_code: "GTOT",
      name: t("shipper.modal.trackingEvents.Gate out"),
    },
    {
      event_type_code: "GTIN",
      name: t("shipper.modal.trackingEvents.Gate in"),
    },
    {
      event_type_code: "LOAD",
      name: t("shipper.modal.trackingEvents.Vessel load"),
    },
    {
      event_type_code: "DEPA",
      name: t("shipper.modal.trackingEvents.Vessel departure"),
    },
    {
      event_type_code: "ARRI",
      name: t("shipper.modal.trackingEvents.Vessel arrival"),
    },
    {
      event_type_code: "DISC",
      name: t("shipper.modal.trackingEvents.Vessel discharge"),
    },
    {
      event_type_code: "AVPU",
      name: t("shipper.modal.trackingEvents.Available for Pick-up"),
    },
    {
      event_type_code: "STUF",
      name: t("shipper.modal.trackingEvents.Stuffed"),
    },
    {
      event_type_code: "STRP",
      name: t("shipper.modal.trackingEvents.Stripped"),
    },
    {
      event_type_code: "PICK",
      name: t("shipper.modal.trackingEvents.Pick-upevent"),
    },
    {
      event_type_code: "DROP",
      name: t("shipper.modal.trackingEvents.Drop-offevent"),
    },
    {
      event_type_code: "AVDO",
      name: t("shipper.modal.trackingEvents.Available for Drop-off"),
    },
    {
      event_type_code: "INSP",
      name: t("shipper.modal.trackingEvents.Inspected"),
    },
    {
      event_type_code: "RSEA",
      name: t("shipper.modal.trackingEvents.Resealed"),
    },
    {
      event_type_code: "RMVD",
      name: t("shipper.modal.trackingEvents.Removed"),
    },
    {
      event_type_code: "CUSS",
      name: t("shipper.modal.trackingEvents.Customs Selected for Scan"),
    },
    {
      event_type_code: "CUSI",
      name: t("shipper.modal.trackingEvents.Customs Selected for Inspection"),
    },
    {
      event_type_code: "CUSR",
      name: t("shipper.modal.trackingEvents.Customs Released"),
    },
    {
      event_type_code: "CROS",
      name: t("shipper.modal.trackingEvents.Crossed"),
    },
    {
      event_type_code: "BOOKED",
      name: t("shipper.modal.trackingEvents.Booked"),
    },
    {
      event_type_code: "AIR_GATE_IN",
      name: t("shipper.modal.trackingEvents.Air gate in"),
    },
    {
      event_type_code: "PARTIALLY_IN_TRANSIT",
      name: t("shipper.modal.trackingEvents.Partially in transit"),
    },
    {
      event_type_code: "IN_TRANSIT",
      name: t("shipper.modal.trackingEvents.In transit"),
    },
    {
      event_type_code: "PARTIALLY_ARRIVED",
      name: t("shipper.modal.trackingEvents.Partially arrived"),
    },
    {
      event_type_code: "ARRIVED",
      name: t("shipper.modal.trackingEvents.Arrived"),
    },
    {
      event_type_code: "PARTIALLY_AIR_GATE_OUT",
      name: t("shipper.modal.trackingEvents.Partially air gate out"),
    },
    {
      event_type_code: "AIR_GATE_OUT",
      name: t("shipper.modal.trackingEvents.Air gate out"),
    },
    {
      event_type_code: "ARCHIVED",
      name: t("shipper.modal.trackingEvents.Archived"),
    },
  ];
  return (
    events.find((e) => e.event_type_code === event)?.name ||
    t("shipper.modal.trackingEvents.Unknown Event")
  );
}
