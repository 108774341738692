import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./../../../locales/en/translation.json";
import translationHY from "./../../../locales/hy/translation.json";
import translationKA from "./../../../locales/ka/translation.json";
import translationRU from "./../../../locales/ru/translation.json";
import translationUZ from "./../../../locales/uz/translation.json";
import translationAZ from "./../../../locales/az/translation.json";
import translationTR from "./../../../locales/tr/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  hy: {
    translation: translationHY,
  },
  ka: {
    translation: translationKA,
  },
  ru: {
    translation: translationRU,
  },
  uz: {
    translation: translationUZ,
  },
  az: {
    translation: translationAZ,
  },
  tr: {
    translation: translationTR,
  },
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on("languageChanged", (lng) => (document.documentElement.lang = lng))
  .init({
    compatibilityJSON: "v3",
    supportedLngs: ["en", "ka", "ru", "hy", "uz", "az", "tr"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["cookie", "htmlTag", "path"],
      caches: ["cookie"],
    },
    resources,
    react: { useSuspense: false },

    // backend: {
    //   loadPath: "/assets/locales/{{lng}}/translation.json",
    // },
  });

export default i18next;
