import React, { Fragment, useEffect, useRef } from "react";
import "./SimilarOrdersNavbar.scss";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import SwiperCore from "swiper/core";

import "swiper/css";
import "swiper/css/free-mode";

SwiperCore.use([FreeMode]);

const SimilarOrdersNavbar = ({ groups }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { orderId } = useParams();
  const { openSidebar } = useSelector((state) => state.userUI);

  const navigateHandler = (item) => {
    let newUrlType = "";

    if (pathname.includes("/info")) {
      newUrlType = "info";
    }

    let newUrl = `/dashboard/tms/orders/${item.id}`;

    if (newUrlType === "info") {
      newUrl += `/info`;
    } else {
      newUrl += ``;
    }
    navigate(newUrl);
  };

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const findIndex = groups
        .slice()
        .reverse()
        .findIndex((item) => item.id.toString() === orderId.toString());

      swiperRef.current.swiper.slideTo(findIndex ? findIndex : 0); // Set the initial active slide index
    }
  }, [groups, orderId]);

  return (
    <Fragment>
      {groups && groups.length > 0 && (
        <div id='SimilarOrdersNavbar' className={openSidebar ? "max" : "min"}>
          <Swiper
            ref={swiperRef}
            spaceBetween={16}
            slidesPerView='auto'
            freeMode={true}
            modules={[FreeMode]}
          >
            {groups
              .slice()
              .reverse()
              .map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div
                      id={orderId.toString() === item.id.toString() ? "activeLink" : ""}
                      style={{
                        borderColor:
                          orderId.toString() === item.id.toString()
                            ? `${item?.statusTms?.color1}`
                            : "transparent",
                      }}
                      className={item.truck ? "content assign" : "content"}
                      onClick={() => navigateHandler(item)}
                    >
                      <div
                        style={{ backgroundColor: `${item?.statusTms?.color1}` }}
                        className='dot'
                      ></div>
                      <div
                        className='status'
                        style={{
                          backgroundColor: `${item?.statusTms?.color2}`,
                          color: `${item?.statusTms?.color1}`,
                        }}
                      >
                        {item.statusTms?.name}
                      </div>
                      <p className='message'>{item?.tracking}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      )}
    </Fragment>
  );
};

export default SimilarOrdersNavbar;
