import React from "react";
import "./Dashboard.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IoIosWarning } from "react-icons/io";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import ShipperCards from "../../../../componentsDashboard/ShipperOverview/ShipperCards/ShipperCards";
import ShipperCharts from "../../../../componentsDashboard/ShipperOverview/ShipperCharts/ShipperCharts";
import ActivityTable from "../../../../componentsDashboard/ShipperOverview/ActivityTable/ActivityTable";
import QuotesTable from "../../../../componentsDashboard/ShipperOverview/QuotesTable/QuotesTable";
import ShipperTitleSection from "../../../../componentsDashboard/ShipperTitleSection/ShipperTitleSection";

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const isSuspended = user?.rejected_at;

  return (
    <Wrapper documentTitle={t("shipper.titles.Overview")}>
      <section id='SDashboard'>
        <ShipperTitleSection
          title={t("shipper.titles.Overview")}
          subtitle={t("shipper.desc.Hi, welcome back !", {
            name: user?.name,
          })}
        />
        {isSuspended && (
          <div className='suspensionNoticeContainer'>
            <div className='icon'>
              <IoIosWarning />
            </div>
            <div>
              <h1>{t("shipper.desc.Account Suspension Notice")}</h1>
              <h2>
                {t(
                  "shipper.desc.We were unable to contact you to verify your account. Please contact your personal manager as soon as possible to remove the suspension status and continue placing orders"
                )}
              </h2>
            </div>
          </div>
        )}

        <div className='SDashboardStatistic'>
          <ShipperCards />
          <ShipperCharts />
        </div>
        <div className='SDashboardTables'>
          <ActivityTable />
          <QuotesTable />
        </div>
      </section>
    </Wrapper>
  );
};

export default Dashboard;
