import _request from "./../_request";

const getTracking = async (ISO, TOKEN, data) => {
  let url = "/tracker/trucks?";
  const { keyword } = data || {};
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrderTracking = async (ISO, TOKEN, order_id) => {
  const config = {
    url: `/tracking/order/${order_id}/`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getContainerTracking = async (ISO, TOKEN, order_id, container_id) => {
  const config = {
    url: `/tracking/order/${order_id}/container/${container_id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getAirTracking = async (ISO, TOKEN, order_id, transport_id) => {
  const config = {
    url: `/tracking/order/${order_id}/air/${transport_id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const trackerManagerService = {
  getTracking,
  getOrderTracking,
  getContainerTracking,
  getAirTracking,
};

export default trackerManagerService;
