import React, { useState } from "react";
import "./AirTrackingItem.scss";

import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { TbProgress } from "react-icons/tb";
import { FaPlaneUp } from "react-icons/fa6";

import AirTrackingItemEvent from "../AirTrackingItemEvent/AirTrackingItemEvent";

const AirTrackingItem = ({ title, icon, done, data }) => {
  const [open, setOpen] = useState(true);

  return (
    <div id='AirTrackingItem'>
      <div
        className='header'
        style={{ cursor: data ? "pointer" : "" }}
        onClick={() => data && setOpen(!open)}
      >
        <div className='titleContainer'>
          <div className='icon'>{icon}</div>
          <div className='title'>{title}</div>
          {data && (
            <div className='icon'>
              <IoIosArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
            </div>
          )}
        </div>
        <div className={`statusIcon ${done ? "done" : ""}`}>
          {done ? <FaCheckCircle /> : <TbProgress />}
        </div>
      </div>
      {data && open && (
        <div className='trackingInfoContainer'>
          {data?.map((item, index) => {
            return (
              <div key={index} className='trackingInfoItem'>
                <div className='leftSide'>{item?.arr_iata}</div>
                <div className='rightSide'>
                  <div className='rightSideContainer' key={index}>
                    <div className='center'>
                      <div className={`icon current`}>
                        <FaPlaneUp
                          style={{
                            color: item?.status === "ARRIVED" ? "#0AB39C" : "#878A99",
                          }}
                        />
                      </div>
                      <div
                        className={`line actual`}
                        style={{
                          backgroundColor: item?.status === "ARRIVED" ? "#0AB39C" : "#878A99",
                        }}
                      />
                    </div>
                    <AirTrackingItemEvent item={item} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AirTrackingItem;
