import React, { Fragment, useEffect } from "react";
import "./AirTracking.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAirTracking } from "../../../../../store/trackerManager/trackerManagerSlice";
import moment from "moment/moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import { FaArrowRight, FaPlaneUp, FaTruck } from "react-icons/fa6";
import { MdEditCalendar, MdOutlineUpdate } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import Character from "../../../../UI/Character/Character";
import AirTrackingItem from "./AirTrackingItem/AirTrackingItem";
import { useTrackingEvent } from "../../../../../helper/useTrackingEvent";

const AirTracking = ({ order, transport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getAirTrackingLoading, getAirTrackingResult } = useSelector(
    (state) => state.trackerManager
  );
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const lastLocation = `${useTrackingEvent(getAirTrackingResult?.status)} • ${
    getAirTrackingResult?.dst_iata
  } • ${moment(getAirTrackingResult?.pdc_arrival_ts)?.format("DD MMM YYYY HH:mm")} `;

  const estimateArrival =
    moment(getAirTrackingResult?.pdc_arrival_ts).format("DD MMM YYYY HH:mm") || "-";
  const lastUpdate = moment(getAirTrackingResult?.updated_at)?.fromNow();

  const lastMovement =
    getAirTrackingResult?.movements?.[getAirTrackingResult?.movements?.length - 1];
  const skdMoment = moment(lastMovement?.skd_arrival_ts || 0, "YYYY-MM-DD HH:mm:ss");
  const actMoment = moment(lastMovement?.act_arrival_ts || 0, "YYYY-MM-DD HH:mm:ss");
  const durationInMs = skdMoment.diff(actMoment);
  const durationInHours = durationInMs / (1000 * 60);

  useEffect(() => {
    if (transport) {
      dispatch(
        getAirTracking({
          orderId: order?.id,
          transportId: transport,
        })
      );
    }
  }, [dispatch, transport, order]);

  return getAirTrackingLoading ? (
    <LoadingPage />
  ) : (
    <div id='AirTracking'>
      {Object.keys(getAirTrackingResult)?.length > 0 ? (
        <Fragment>
          <div className='transportInfoContainer'>
            <div className='transportInfo'>
              <div className='destinationsContainer'>
                <div className='destination'>
                  <Character
                    title={order?.cargos?.[0]?.locations?.[0]?.formatted_address}
                    max={20}
                  />
                </div>
                <div className='arrow'>
                  <FaArrowRight />
                </div>
                <div className='destination'>
                  <Character
                    title={
                      order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]
                        ?.formatted_address
                    }
                    max={20}
                  />
                </div>
              </div>
            </div>
            <div className='lastUpdateContainer'>
              <div className='icon'>
                <MdOutlineUpdate />
              </div>
              <p>
                {t("shipper.modal.Last update")} {lastUpdate}
              </p>
            </div>
          </div>
          <div className='trackingInfoContainer'>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <FaRegCalendarAlt />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Estimate time of arrival")}</div>
                <div className='value'>{estimateArrival}</div>
              </div>
            </div>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <MdEditCalendar />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Behind schedule")}</div>
                <div className='value' style={{ color: durationInHours < 0 ? "red" : "#0AB39C" }}>
                  {durationInHours === 0 || durationInHours ? durationInHours : "-"}{" "}
                  {t("shipper.units.hours")}
                </div>
              </div>
            </div>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <IoLocationOutline />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Last location")}</div>
                <div className='value'>{lastLocation || "-"}</div>
              </div>
            </div>
          </div>
          {order?.cargos?.[0]?.locations?.[0]?.type === "LOCALITY" && (
            <AirTrackingItem
              title={t("shipper.modal.Road freight")}
              icon={<FaTruck />}
              done={true}
            />
          )}
          <AirTrackingItem
            title={t("shipper.modal.Air freight")}
            icon={<FaPlaneUp />}
            done={order?.statusClient?.id === 4}
            data={getAirTrackingResult?.movements}
          />
          {order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]?.type ===
            "LOCALITY" && (
            <AirTrackingItem
              title={t("shipper.modal.Road freight")}
              icon={<FaTruck />}
              done={order?.statusClient?.id === 4}
            />
          )}
        </Fragment>
      ) : (
        <div className='noData'>
          {t("shipper.modal.Tracking on this container is currently unavailable")}
        </div>
      )}
    </div>
  );
};

export default AirTracking;
