import React from "react";
import "./AirTrackingItemEvent.scss";

import moment from "moment";

import { useTrackingEvent } from "../../../../../../helper/useTrackingEvent";

const AirTrackingItemEvent = ({ item }) => {
  const eventName = useTrackingEvent(item?.status);
  const vesselInfo =
    eventName && item?.flight_no ? `${eventName} (${item?.flight_no})` : eventName || "";

  return (
    <div id='AirTrackingItemEvent'>
      <div className='event'>{vesselInfo}</div>
      <div className='eventDate'>{moment(item?.act_arrival_ts)?.format("DD MMM YYYY HH:mm")}</div>
    </div>
  );
};

export default AirTrackingItemEvent;
